import {
    createSlice,
} from "@reduxjs/toolkit";

const middleware = [
];

// AUTH STATE
const authState = {
    token: "",
    error: "",
    login: ""
};

const authSlice = createSlice({
    name: "auth",
    initialState: authState,
    reducers: {
        loginSuccess: (state, action) => {
            state.token = action.payload;
        },
        loginFailed: (state, action) => {
            state.error = action.payload;
        },
        setLogin: (state, action) => {
            state.login = action.payload;
        }
    },
});

export const { loginSuccess, loginFailed, setLogin } = authSlice.actions;
export const authReducer = authSlice.reducer;
