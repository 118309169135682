import React, { Suspense, lazy } from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import './App.css';
const Medicaments = lazy(() => import("./pages/medicaments/Medicaments"));
const Alimentation = lazy(() => import ("./pages/alimentation/Alimentation"));
const Clients = lazy(() => import('./pages/clients/clients'));
const Login = lazy(() => import('./pages/login/login'));
const Factures = lazy(() => import('./pages/factures/Factures'));
const Config = lazy(() => import('./pages/Config/Config'));

const ContentRoute = () => {

    return  (
            <Suspense fallback={<div>Chargement...</div>}>
                <Routes>
                    <Route path="/" element={<Navigate to="/front/clients" />}/>
                    <Route path="/front" element={<Navigate to="/front/clients" />}/>
                    <Route path="/login" element={<Navigate to="/front/login" />}/>

                    <Route path="/front/login" element={<Login/>}/>
                    <Route path="/front/clients" element={<Clients/>}/>
                    <Route path="/front/medicaments" element={<Medicaments/>} />
                    <Route path="/front/alimentation" element={<Alimentation/>} />
                    <Route path={"/front/factures"} element={<Factures/>} />
                    <Route path={"/front/config"} element={<Config/>} />
                </Routes>
            </Suspense>
    );
}


export default ContentRoute;