import React from 'react';

import './App.css';
import { SnackbarProvider } from './context/snackbar';
import MainPage from "./pages/mainPage/mainPage";
import {createTheme, ThemeProvider} from "@mui/material";
import SetupInterceptors from "./components/AxiosInterceptor";
import { ReduxRouter } from '@lagunovsky/redux-react-router'

import Store, {history} from "./store/rootStore";
import {Provider} from "react-redux";
import {contextPath} from "./components/AxiosService";

const theme = createTheme({
    components: {
        MuiFormControl: {
            styleOverrides: {
                root:{
                    width: '100%',
                }
            },
            defaultProps: {
                variant: "standard"
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard"
            }
        }
    }
})


const App = () => {
    SetupInterceptors(history, Store);

    return  (
        <Provider store={Store}>
            <ReduxRouter
                history={history}
                store={Store}
                basename={contextPath}
            >

                <ThemeProvider theme={theme}>
                    <SnackbarProvider>
                        <MainPage />
                    </SnackbarProvider>
                 </ThemeProvider>
            </ReduxRouter>
        </Provider>

    );

}


export default App;