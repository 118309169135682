import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";
import getBaseUrl from "../../components/common/baseUrl";
import Store from "../rootStore";
import AxiosService from "../../components/AxiosService";


// AUTH STATE
const clientsState = {
    clients: [],
    loadingClient : false,
    currentClient: {},
    currentAnimal: {},
    vaccins: []
};

export const getAllClients = createAsyncThunk("clientStore/getAllClients", async (payload, thunkAPI) => {
    const response = await AxiosService.get('/api/v1/client/list', {withCredentials: true})
    let _resp = {responseData: response.data}
    if (payload !== undefined && payload.clientId !== undefined && payload.clientId !== null) {
        _resp["clientId"] = payload.clientId;
    }
    return _resp;
});

export const getClient = createAsyncThunk("clientStore/getClient", async (payload, thunkAPI) => {
    const response = await AxiosService.get('/api/v1/client/'+payload, {withCredentials: true});
    return response.data;
});

export const deleteClient = createAsyncThunk("clientStore/deleteClient", async(payload, thunkAPI) => {
    const response = await AxiosService.delete("/api/v1/client/"+payload, {withCredentials: true});
    const resp2 = await AxiosService.get('/api/v1/client/list', {withCredentials: true});
    return resp2.data;
});

export const getAnimalVaccins = createAsyncThunk("clientStore/vaccinsAnimal", async (payload, thunkAPI) => {
    if (payload === null) {
        return [];
    }
   const response = await AxiosService.get( "/api/v1/animal/"+payload+"/vaccins", {withCredentials: true})
   return response.data;
});

export const getAnimal = createAsyncThunk("clientStore/getAnimal", async (payload, thunkAPI) => {
    const response = await AxiosService.get("/api/v1/animal/"+payload, {withCredentials: true});
    return response.data;
});

export const savingClient = async(payload) =>  {
    try {
       const response = await AxiosService.post( "/api/v1/client", payload, {withCredentials: true});
       return response.data;
    } catch (e) {
        throw Error;
    }
}

/*export const saveClient = createAsyncThunk("clientStore/saveClient", async(payload, thunkAPI) => {
        const response = await AxiosService.post( "/givet/api/v1/client", payload, {withCredentials: true});
        const resp2 = await AxiosService.get('/givet/api/v1/client/list', {withCredentials: true})

        let _ret = {};
        _ret.clientList = resp2.data;
        _ret.client = response.data;
        return _ret;
});
*/
export const addVaccin = createAsyncThunk("clientStore/addVaccin", async(payload, thunkAPI) => {
    const response = await AxiosService.post("/api/v1/vaccin",  payload, {withCredentials: true});
    const resp2 = await AxiosService.get( "/api/v1/animal/"+payload.animalId+"/vaccins", {withCredentials: true});

    return resp2.data;
})

export const delVaccin = createAsyncThunk("clientStore/delVaccin", async(payload, thunkAPI) => {
    const response = await AxiosService.delete("/api/v1/vaccin/"+payload.vaccinId, {withCredentials: true});
    const resp2 = await AxiosService.get("/api/v1/animal/"+payload.animalId+"/vaccins", {withCredentials: true});
    return resp2.data;
})

const clearSelection = (state, payload) => {
    state.currentAnimal = {};
    state.vaccins = [];
    state.clients = payload.map(client => {
        client.animaux.forEach(an => an.isSelected = false);
        client.expanded = false;
        return client;
    });
}

export const selectOneAnimalHelper = async (animalId) => {
    await Store.dispatch(selectOne(animalId));
    await Store.dispatch(getAnimalVaccins(animalId));
}

const _expandOneClient = (state, action) => {
    let _clients = state.clients;
    clearSelection(state, _clients);

    _clients.forEach(client => {
        if (client.id === action.payload) {
            client.expanded = true;
            state.currentClient = client;
        }else{
            client.expanded = false;
        }
    });
    state.clients = _clients;

}

const selectOneAnimal = (state, action) => {
    state.currentAnimal = {};
    state.clients = state.clients.map(client => {
        client.animaux.forEach(an => {
                if (an.id === action.payload) {
                    an.isSelected = true;
                    state.currentAnimal = an;
                } else
                    an.isSelected = false;
            }
        );
        return client;
    });

}

const clientsSlice = createSlice({
    name: "clientStore",
    initialState: clientsState,
    reducers: {
        selectOne: (state, action ) => {
            selectOneAnimal(state, action);
        },
        expandOneClient: (state, action) => {
            _expandOneClient(state, action);
        },
        updateAnAnimal: (state, action) => {
            const newAnimal = action.payload;
            let _clients = [...state.clients];
            const clientIdx = _clients.findIndex(c => c.id === newAnimal.clientId);
            if (clientIdx !== -1) {

                let _animaux = [..._clients[clientIdx].animaux];
                let animalIdx = _animaux.findIndex(a => a.id === newAnimal.id);
                if (animalIdx !== -1) {
                    _animaux[animalIdx] = newAnimal;
                } else {
                    _animaux.push(newAnimal);
                }
                _clients[clientIdx].animaux = _animaux;
                state.clients = _clients;
            }
            state.currentAnimal = newAnimal;
        },
        clearCurrentClient :(state, action) => {
            state.currentClient = {};
        },
        clearCurrentAnimal: (state, action) => {
            state.currentAnimal = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllClients.fulfilled, (state, { payload }) => {
                // Add user to the state array
                 clearSelection(state, payload.responseData);
                 state.loadingClient = false;
                 if (payload.clientId !== null && payload.clientId !== undefined) {
                     _expandOneClient(state, {payload: payload.clientId});
                 }
             })
            .addCase(getAllClients.pending, (state) => {
                state.loadingClient = true;
            })
            .addCase(getAllClients.rejected, (state, payload) =>{
                state.loadingClient = false;
            })
            .addCase(getAnimalVaccins.fulfilled, (state, {payload}) => {
                state.vaccins = payload;
            })
            /*.addCase(saveClient.fulfilled, (state, {payload}) => {
                clearSelection(state, payload.clientList);
                _expandOneClient(state, {payload: payload.client.id});
            })*/
            .addCase(addVaccin.fulfilled, (state, {payload}) => {
                state.vaccins = payload;
            })
            .addCase(delVaccin.fulfilled, (state, {payload}) => {
                state.vaccins = payload;
            })
            .addCase(deleteClient.fulfilled, (state, { payload }) => {
                // Add user to the state array
                clearSelection(state, payload);
            })
            .addCase(getAnimal.fulfilled, (state, {payload}) => {
                state.currentAnimal = payload;
            })
            .addCase(getClient.fulfilled, (state, {payload}) => {
                state.currentClient = payload;

            })
    }
});


export const {selectOne, expandOneClient, updateAnAnimal, clearCurrentClient, clearCurrentAnimal} = clientsSlice.actions;
export default clientsSlice.reducer;
