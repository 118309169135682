import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";


// AUTH STATE
const navigationState = {
    page: "Main"
};



const navigationSlice = createSlice({
    name: "navigation",
    initialState: navigationState,
    reducers: {
        navigateTo: (state, action ) => {
            state.page = action.payload;
        }

    },
});


export const {navigateTo} = navigationSlice.actions;
export default navigationSlice.reducer;
