import axios from "axios";

let headers = {};
//export const baseURL = "http://localhost:8082"
//
const _contextPath = window.location.pathname.split('/')[1];
export const contextPath = _contextPath === "front" ? "" : "/"+_contextPath;
//
export const baseURL = "";
//const jwtToken = localStorage.getItem("Authorization");

/*if (jwtToken) {
    headers.Authorization = 'Bearer ' + jwtToken;
}*/

const axiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: true
});

export default axiosInstance;