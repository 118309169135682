import React, { createContext, useState, useCallback } from 'react';

import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
    const [ open, setOpen ] = useState(false);
    const [ alert, setAlert ] = useState({ severity: 'error' });
    const [timeout , setTimeout] = useState(5000);
    
    const openSnackbar = useCallback((message, severity, timeout) => {
        setAlert({ message, severity });
        setOpen(true);
        setTimeout(timeout);
    }, [ setAlert, setOpen ]);

    const openErrorSnackbar = useCallback((message, _timeout = 5000) => openSnackbar(message, 'error', _timeout), [ openSnackbar ]);

    const openWarningSnackbar = useCallback((message, _timeout = 5000) => openSnackbar(message, 'warning', _timeout), [ openSnackbar ]);

    const openInfoSnackbar = useCallback((message, _timeout = 5000) => openSnackbar(message, 'info', _timeout), [ openSnackbar ]);

    const openSuccessSnackbar = useCallback((message, _timeout = 5000) => openSnackbar(message, 'success', _timeout), [ openSnackbar ]);

    const handleClose = useCallback((_event, reason) => {
        if(reason !== 'clickaway') {
            setOpen(false);
        }
    }, [ setOpen ]);
    
    return (
        <SnackbarContext.Provider value={{
            openErrorSnackbar,
            openWarningSnackbar,
            openInfoSnackbar,
            openSuccessSnackbar,
        }}>
            { children }
            <Snackbar
                open={open}
                autoHideDuration={timeout}
                onClose={handleClose}
            >
                <Alert
                    severity={alert.severity}
                    onClose={handleClose}
                >
                    { alert.message }
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

export default SnackbarContext;