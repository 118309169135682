import {useLocation, useNavigate} from "react-router-dom";
import axiosInstance from "./AxiosService";
import qs from "qs";


const SetupInterceptors = (history) => {

    const handleSuccess = (response) => {
        return response;
    }

    const handleError = (error) => {
        const status = error?.response?.status;
        switch (status) {
            case (401) :
                history.push("/front/login");
                break;
        }
        return Promise.reject(error);
    }

    axiosInstance.interceptors.response.use(handleSuccess, handleError);
    axiosInstance.interceptors.request.use(function (config) {
        // Serialize the parameteters
        config.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' })
        return config
    });
}

export default SetupInterceptors;