import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import PetsIcon from '@mui/icons-material/Pets';
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import VaccinesIcon from '@mui/icons-material/Vaccines';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import * as React from "react";
import Store from "../../store/rootStore";
import { push } from "@lagunovsky/redux-react-router";
import {useSelector} from "react-redux";


const Menu = (props) => {

    const {login} = useSelector((state) => state.auth);

    return (
        <div>
            <List>

                <ListItem button key={"clients"} onClick={() => Store.dispatch(push("/front/clients"))}>
                    <ListItemIcon><PetsIcon /></ListItemIcon>
                    <ListItemText primary={"Clients"} />
                </ListItem>

                <ListItem button key={"medicaments"} onClick={() => Store.dispatch(push("/front/medicaments"))}>
                    <ListItemIcon><VaccinesIcon /></ListItemIcon>
                    <ListItemText primary={"Medicaments"} />
                </ListItem>
                <ListItem button key={"Alimentation"} onClick={() => Store.dispatch(push("/front/alimentation"))}>
                    <ListItemIcon><LocalPizzaIcon /></ListItemIcon>
                    <ListItemText primary={"Alimentation"} />
                </ListItem>
                <ListItem button key={"Autres"} onClick={() => Store.dispatch(push("/front/others"))}>
                    <ListItemIcon><SportsSoccerIcon /></ListItemIcon>
                    <ListItemText primary={"Autres fournitures"} />
                </ListItem>
                <ListItem button key={"factures"} onClick={() => Store.dispatch(push("/front/factures"))}>
                    <ListItemIcon><ReceiptLongIcon /></ListItemIcon>
                    <ListItemText primary={"Factures"} />
                </ListItem>
                { login && login.toUpperCase() === "MAILYS" &&
                    <ListItem button key={"config"} onClick={() => Store.dispatch(push("/front/config"))}>
                        <ListItemIcon><SettingsIcon /></ListItemIcon>
                        <ListItemText primary={"Configuration"} />
                    </ListItem>
                    }
            </List>
            <Divider />

        </div>
    )
}

export default Menu;