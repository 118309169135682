import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import AxiosService from "../../components/AxiosService";


const stockState = {
    medicaments: [],
    loadingMedics : false,
    currentMedic: {},
    currentAlim: {},

    alimentations: [],

    autres: []
};
export const getAllMedicaments = createAsyncThunk("StockStore/medicaments", async (payload, thunkAPI) => {

    const response = await AxiosService.get( "/api/v1/medicaments", {withCredentials: true})
    return response.data;
});

export const getAllAlimentation = createAsyncThunk("StockStore/alimentations", async (payload, thunkAPI) => {

    const response = await AxiosService.get( "/api/v1/stock/alimentations", {withCredentials: true})
    return response.data;
});

export const getAllOthers = createAsyncThunk("StockStore/others", async (payload, thunkAPI) => {

    const response = await AxiosService.get( "/api/v1/stock/others", {withCredentials: true})
    return response.data;
});

export const saveMedicament = async (payload) => {
    try {
        const response = await AxiosService.post("/api/v1/medicaments", payload, {withCredentials: true});
        return response.data;
    }catch (e) {
        throw Error;
    }
}

export const saveAlimentation = async (payload) => {
    try {
        const response = await AxiosService.post("/api/v1/stock/alimentations", payload, {withCredentials: true});
        return response.data;
    }catch (e) {
        throw Error;
    }

}



const StockSlice = createSlice( {
    name:"StockStore",
    initialState: stockState,
    reducers: {
        setCurrentMedic : (state, action) => {
            state.currentMedic = action.payload;
        },
        setCurrentAlim : (state, action) => {
            state.currentAlim = action.payload;
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(getAllMedicaments.fulfilled, (state, {payload}) => {
                state.medicaments = payload;
            })
            .addCase(getAllAlimentation.fulfilled, (state, {payload}) => {
               state.alimentations = payload;
            })
            .addCase(getAllOthers.fulfilled, (state, {payload}) => {
                state.autres = payload;
            })
    })

});
export const {setCurrentMedic, setCurrentAlim} = StockSlice.actions;

export default StockSlice.reducer;