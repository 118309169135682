import {configureStore} from "@reduxjs/toolkit";
import {authReducer} from "./slices/auth";
import navigationReducer from "./slices/navigation"
import clientsReducer from "./slices/clientsSlice"
import consultationStore from "./slices/consultationSlice"
import StockSlice from "./slices/stockSlice"
import { createRouterMiddleware, createRouterReducer} from '@lagunovsky/redux-react-router'
import {createBrowserHistory} from "history"
import thunk from 'redux-thunk'

const ROUTER_CALL_HISTORY_METHOD = '@@router/CALL_HISTORY_METHOD';


export const history = createBrowserHistory();
createRouterMiddleware(history);

const reducer = {
    auth: authReducer,
    navigate: navigationReducer,
    clientStore: clientsReducer,
    consultationStore: consultationStore,
    router: createRouterReducer(history),
    stockStore: StockSlice
    //visibility: visibilityReducer,
}

const routerMiddleware2 = ()=>(next)=>(action)=>{
    if (action.type !== ROUTER_CALL_HISTORY_METHOD) {
        return next(action);
    }
    history[action.payload.method](...action.payload.args);
}

const Store = configureStore({
    reducer: reducer,
//    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(routerMiddleware2).concat(thunk),
    devTools: process.env.NODE_ENV !== 'production',
    //   preloadedState,
    //   enhancers: [reduxBatch],
})

export default Store;